<template>
  <div class="auth-wrapper auth-v2 min-vh">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="side-panel fade-in">
            <!-- <img
              height="100%"
              width="100%"
              class="side-panel-image"
              :src="require('./assets/landignpage.jpg')"
            /> -->
            <v-img
              :src="
                !$vuetify.theme.isDark
                  ? require('./assets/white-background.jpg')
                  : require('./assets/dark-background.png')
              "
              class="min-vh"
            >
              <v-row
                class="d-flex column justify-center align-center"
                style="min-height: 100vh"
              >
                <v-col cols="6" class="d-flex flex-column">
                  <!-- <h1
                    class="font-weight-bold mb-4 text-h1 slide-in-left"
                    :class="
                      $vuetify.breakpoint.mdAndUp ? 'text-h2 ' : 'text-h2'
                    "
                    style="font-family: 'Urbanist' !important"
                    :style="
                      !$vuetify.theme.isDark && 'color: #FFFFFF !important;'
                    "
                  >
                    {{ $t("Corporate Panel") }}
                  </h1> -->
                  <img
                    class="slide-in-down"
                    :src="
                      !$vuetify.theme.isDark
                        ? require('./assets/dark-logo.svg')
                        : require('./assets/white-logo.svg')
                    "
                    alt="Logo"
                  />
                </v-col>
              </v-row>
            </v-img>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat v-if="!sent">
                <v-card-text
                  class="d-flex justify-center align-center text-center mb-8"
                  v-if="$vuetify.breakpoint.mdAndDown"
                >
                  <v-img
                    :src="
                      require(`@/assets/Logo_${
                        $vuetify.theme.dark ? 'blanco' : 'negro'
                      }.svg`)
                    "
                    max-width="100%"
                    class=""
                  ></v-img>
                </v-card-text>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t("Recover password") }}
                  </p>
                  <p class="text--primary mb-2">
                    {{ $t("To recover your password, enter your email") }}.
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <ValidationObserver v-slot="{ invalid }">
                    <v-form @submit.prevent="handleSend()" spellcheck="false">
                      <ValidationProvider
                        :name="$t('Email')"
                        rules="required|email"
                        v-slot="{ errors }"
                        :debounce="450"
                      >
                        <!-- :detectInput="false" -->
                        <v-text-field
                          v-model="userData.email"
                          outlined
                          :label="$t('Email')"
                          :placeholder="$t('Email')"
                          hide-details="auto"
                          class="mb-6"
                          autocapitalize="none"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>

                      <v-btn
                        block
                        color="primary"
                        type="submit"
                        class="mb-4"
                        rounded
                        :disabled="invalid"
                      >
                        {{ $t("Continue") }}
                      </v-btn>

                      <div
                        class="
                          d-flex
                          align-center
                          justify-space-between
                          flex-wrap
                        "
                        v-if="true"
                      >
                        <router-link class="ms-3" :to="{ name: 'auth-login' }">
                          <v-icon small>
                            {{ icons.mdiArrowLeft }}
                          </v-icon>
                          <span class="ml-2">{{ $t("GOBACK") }}</span>
                        </router-link>
                        <span>
                          <v-fade-transition mode="out-in" v-if="!false">
                            <v-avatar
                              color="grey-lighten-2"
                              size="36"
                              @click="handleLocale()"
                              class="mySwitcher"
                            >
                              {{
                                locales.find((l) => l.locale === $i18n.locale)
                                  .title
                              }}
                            </v-avatar>
                          </v-fade-transition>

                          <app-bar-theme-switcher
                            class="mx-4"
                          ></app-bar-theme-switcher>
                        </span>
                      </div>
                    </v-form>
                  </ValidationObserver>
                </v-card-text>
              </v-card>
              <v-card flat v-if="sent">
                <v-card-text
                  class="d-flex justify-center align-center text-center mb-8"
                  v-if="$vuetify.breakpoint.mdAndDown"
                >
                  <v-img
                    :src="
                      require(`@/assets/Logo_${
                        $vuetify.theme.dark ? 'blanco' : 'negro'
                      }.svg`)
                    "
                    max-width="100%"
                    class=""
                  ></v-img>
                </v-card-text>
                <v-card-text>
                  <p
                    class="
                      text-2xl
                      font-weight-semibold
                      text--primary
                      mb-2
                      d-flex
                      align-center
                    "
                  >
                    {{ $t("Check your email") }}
                    <!-- <v-icon class="ml-2">
                      {{ icons.mdiCheckBold }}
                    </v-icon> -->
                  </p>
                  <p class="mb-2 text--primary">
                    {{ $t("We've sent an email to") }}
                    <strong>{{ userData.email }}</strong>
                    {{
                      $t(
                        "with instructions to reset your password. Check your inbox, including the spam folder"
                      )
                    }}.
                  </p>
                </v-card-text>
                <v-card-text>
                  <div
                    class="d-flex align-center justify-space-between flex-wrap"
                    v-if="true"
                  >
                    <!-- <span>
                      <v-fade-transition mode="out-in" v-if="!false">
                        <v-avatar
                          color="grey-lighten-2"
                          size="36"
                          @click="handleLocale()"
                          class="mySwitcher"
                        >
                          {{
                            locales.find((l) => l.locale === $i18n.locale).title
                          }}
                        </v-avatar>
                      </v-fade-transition>

                      <app-bar-theme-switcher
                        class="mx-4"
                      ></app-bar-theme-switcher>
                    </span> -->
                    <router-link
                      class="ms-3 d-flex justify-center align-center"
                      :to="{ name: 'auth-login' }"
                    >
                      <v-icon small>
                        {{ icons.mdiArrowLeft }}
                      </v-icon>
                      <span class="ml-2">{{ $t("GOBACK") }}</span>
                    </router-link>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mdiArrowLeft, mdiCheckBold } from "@mdi/js";
import AppBarThemeSwitcher from "@core/layouts/components/app-bar/AppBarThemeSwitcher.vue";
import { setVeeLocale } from "@/utils";
import { loadLanguageAsync } from "@/plugins/i18n";
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
  components: {
    AppBarThemeSwitcher,
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
        mdiCheckBold,
      },
      userData: {
        email: "",
      },
      locales: [
        {
          title: "ES",
          locale: "es",
        },
        {
          title: "EN",
          locale: "en",
        },
      ],
      sent: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    handleLocale() {
      let prevLocal = this.$i18n.locale;
      let locale = "";
      switch (prevLocal) {
        case "es":
          locale = "en";
          break;
        case "en":
          locale = "es";
          break;
        default:
          break;
      }
      setVeeLocale(locale);
      loadLanguageAsync(locale);
    },
    async handleSend() {
      try {
        this.setLoading(true);
        const resp = await axios.post(
          `${process.env.VUE_APP_STAGING}/v2/corporate/forgot_password`,
          this.userData
        );
        if (resp.data.success) {
          this.sent = true;
        }
      } catch (error) {
        console.log(error);
        if (
          error.response?.data?.error_description ===
          '"email" must be a valid email'
        )
          this.$dialog.notify.error(this.$t("The email is not valid"));
        else
          this.$dialog.notify.error(
            this.$t("An error occurred, please try again later")
          );
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/preset/preset/pages/auth.scss";
.side-panel {
  // background-image: url("../assets/login_side_panel.png");
  background: white;
  max-height: 100vh;
  &-image {
    object-fit: cover;
    // object-fit: contain;
  }
}
.mySwitcher:hover {
  cursor: pointer;
}
</style>
